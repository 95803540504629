const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992
};

let slideMenu = {
    menu: null,
    menuElement: null,
    menuSelector: '.slide-menu',
    menuUlSelector: '.slide-menu ul',
    init: () => {
        slideMenu.menuElement = document.querySelector(slideMenu.menuSelector);
        if (slideMenu.menuElement) {
            slideMenu.create();
            slideMenu.control();
        }
        slideMenu.menuElement.addEventListener('sm.forward', function () {
            slideMenu.menuElement.scrollTop = 0;
        });
    },
    control: () => {
        enquire.register(`screen and (min-width:${breakpoints.lg}px)`, {
            match : function() {
                slideMenu.destroy();
                slideMenu.menu = null;
                delete slideMenu.menu;
            },
            unmatch : function() {
                slideMenu.create();
            }
        });
    },
    create: () => {
        slideMenu.menu = new SlideMenu(slideMenu.menuElement,{
            showBackLink: true,
            backLinkBefore: '<i class="material-icons">chevron_left</i> ',
            submenuLinkAfter: ' <i class="material-icons">chevron_right</i>'
        });
    },
    destroy: ()=> {
        slideMenu.menu.destroy();
        let oldNode = document.querySelector(slideMenu.menuUlSelector);
        let newNode = oldNode.cloneNode(true);
        oldNode.parentNode.insertBefore(newNode, oldNode);
        oldNode.parentNode.removeChild(oldNode);
    }
};


// SEARCH
let search = {
    selectors: {
        button: '.btn--search',
        container: '.search__container'
    },
    classes: {
        hide: 'd-none',
        fixed: 'position-fixed'
    },
    button: null,
    container: null,
    init: () => {
        search.button = document.querySelector(search.selectors.button);
        search.container = document.querySelector(search.selectors.container);
        if (search.button) {
            search.button.addEventListener('click', search._clickHandler);
        }
    },
    _clickHandler: (ev) => {
        search.container.classList.toggle(search.classes.hide);
        document.body.classList.toggle(search.classes.fixed);
    }
};

// SEARCH
let jsLinks = {
    link: null,
    elements: null,
    selector: '.js--link',
    button: '.btn',
    init: () => {
        let els = document.querySelectorAll(jsLinks.selector);
        if (els.length) {
            els.forEach(function(el) {
                el.addEventListener('click', function(e) {
                    document.location.href = this.dataset.link;
                });
                el.addEventListener('hover', function(e) {
                    this.querySelector(jsLinks.button).dispatchEvent('hover');
                })
           });
       }
    }
};

document.addEventListener("DOMContentLoaded", function () {
    slideMenu.init();
    search.init();
    jsLinks.init();

    let f = document.querySelector('.js-expand');
    if (f) {
        let expandables = document.querySelectorAll('.js-expandable');
        f.addEventListener('focus', function(){
            f.classList.add('expanded');
            expandables.forEach(function(el) {
                el.classList.add('expanded');
            });
        })
    }

});